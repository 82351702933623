import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../models/language.model';


export const languageList: Language[] = [
  new Language("de-DE", "Deutsch (Deutschland)", "de"),
  new Language("de-CH", "Deutsch (Schweiz)", "de"),
  new Language("en-GB", "English (United Kingdom)", "en"),
  new Language("en-US", "English (United States)", "en"),
  new Language("es", "Español (España)", "es"),
  new Language("fr-FR", "Français (France)", "fr"),
  new Language("it-IT", "Italiano (Italia)", "it"),
  new Language("ja-JP", "日本語 (日本 / Japan)", "ja-JP"),
  new Language("pt-BR", "Português (Brasil)", "pt-BR"),
  new Language("pt-PT", "Português (Portugal)", "pt-PT")
];


@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private selectedLanguage: Language = null;

  constructor(
    private translate: TranslateService
  ) { }

  public init(): void {
    this.translate.setDefaultLang("en");
    this.setLanguageFromBrowser();
  }

  public setLanguagefromKey(lang: string): void {
    let language: Language = languageList.find(l => l.key == lang);
    this.setLanguageLanguage(language);
  }

  public getLanguage(): string {
    return this.selectedLanguage.key;
  }

  private setLanguageFromBrowser(): void {
    let lang: string = this.translate.getBrowserCultureLang();
    let language: Language = languageList.find(l => l.key == lang);

    if (!!language) {
      this.setLanguageLanguage(language);
      return;
    }

    if (lang.includes('de')) {
      lang = 'de'
    } else if (lang.includes('en')) {
      lang = 'en'
    } else if (lang.includes('es')) {
      lang = 'es'
    } else if (lang.includes('fr')) {
      lang = 'fr'
    } else if (lang.includes('it')) {
      lang = 'it'
    } else if (lang.includes('ja')) {
      lang = 'ja-JP'
    } else if (lang.includes('pt') && !lang.includes('BR')) {
      lang = 'pt-PT'
    }
    this.selectedLanguage = languageList.find(l => l.code == lang);
    this.translate.use(lang);
  }

  private setLanguageLanguage(language: Language): void {
    if (!!language) {
      this.selectedLanguage = language;
      this.translate.use(language.code);
    }
  }
}
