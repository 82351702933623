import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Injectable, Injector, EventEmitter} from '@angular/core';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AuthApi} from '../api/auth.api';
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { Auth } from "../models/auth.model";


@Injectable()
export class UserInterceptor implements HttpInterceptor {

  private isRefreshingToken: boolean = false;
  private refreshedToken: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private router: Router,
    private authApi: AuthApi
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authToken = this.authApi._getAccessToken();

    if (authToken && req.url.indexOf('pdf.abilium.com') < 0 && req.url.indexOf(environment.AR_URL) < 0 && req.url.indexOf('connect/token') < 0) {

      if (!this.authApi.isTokenExpired) {
        return this.setHeadersAndHandle(req, next, authToken);
      } else if (!this.isRefreshingToken) {
        return this.refreshAuthTokenAndHandle(req, next);
      } else {
        return this.waitForTokenToRefreshAndHandle(req, next);
      }
    } else {
      return next.handle(req).pipe(catchError(response => {
        return this.handleErrors(response, req, next);
      }));
    }
  }

  private setHeadersAndHandle(req: HttpRequest<any>, next: HttpHandler, token: string): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token)
    });
    return next.handle(authReq).pipe(catchError(response => {
      return this.handleErrors(response, req, next);
    }));
  }

  private refreshAuthTokenAndHandle(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.isRefreshingToken = true;
    return this.authApi.refreshAuth().pipe(switchMap((auth: Auth) => {
      this.isRefreshingToken = false;
      this.refreshedToken.emit(auth.access_token);
      console.log('token refreshed');
      return this.setHeadersAndHandle(req, next, auth.access_token);
    }), catchError((error, caught) => {
      return this.handleErrors(error, req, next);
    }));
  }

  private waitForTokenToRefreshAndHandle(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.refreshedToken.pipe(switchMap((token: string) => {
      return this.setHeadersAndHandle(req, next, token);
    }), catchError((error, caught) => {
      return this.handleErrors(error, req, next);
    }));
  }

  private handleErrors(error: any, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(error);
    if (error instanceof HttpErrorResponse) {
      switch ((<HttpErrorResponse>error).status) {
        case 400:
          return this.handleError400(error, req, next);
        case 401:
          return this.handleError401(error, req, next);
        // case 403:
        // return this.handleError403(req, next);
        // case 500:
        // return this.handleError500(req, next);
      }
    }
    //return the error to the method that called it
    return throwError(error);
  }

  private handleError400(error, req: HttpRequest<any>, next: HttpHandler): Observable<never> {
    if (error.error.error == 'invalid_grant') {
      return this.logoutUser();
    }
    return throwError('');
  }

  private handleError401(error, req: HttpRequest<any>, next: HttpHandler): Observable<never> {
    return this.logoutUser();
  }

  private logoutUser(): Observable<never> {
    this.authApi.logout();
    this.router.navigate(['/login']);
    return throwError('');
  }
}

