// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api2.utrilog-platform.ch',
  API_AUTH_URL: 'https://identity.utrilog-platform.ch',
  AR_URL: 'https://ar.bodygee.com',

  API_CLIENT_BASE64: 'Ym9keWdlZS1hcGktYXBwczpBODE4OUNBRS0yNTVGLTRGRTAtQkZENC0wMjI2NTZEQkE4NDc=',  
  API_SCOPE: 'bodygee-api offline_access'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
