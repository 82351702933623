import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import { VersionFE } from '../models/version-fe.model';


@Injectable({
  providedIn: 'root'
})
export class ServerService {

  private VERSION_URL = `${environment.API_URL}/server/version/frontend/v3`;

  constructor(
    private http: HttpClient
  ) { }

  public checkVersion(): void {
    this.getFEVersion().then((newVersion: VersionFE) => {
      console.log(newVersion);
      if (newVersion.version != AppConfig.buildVersion) {
        let d = localStorage.getItem("bs_last_check_reload_error");
        try {
          if (d && (new Date().getTime() - new Date(d).getTime() > 12 * 3600 * 1000)) {
            localStorage.removeItem("bs_reload_error_counter");
            localStorage.removeItem("bs_last_check_reload_error");
          }
        } catch (e) { }

        let count = localStorage.getItem("bs_reload_error_counter");
        if (!count || parseInt(count) < 2) {
          let cnt = 0;
          if (count) {
            cnt = parseInt(count);
          }
          localStorage.setItem("bs_last_check_reload_error", new Date().toUTCString());
          localStorage.setItem("bs_reload_error_counter", (cnt + 1) + "");
          window.location.reload();
        }
      } else {
        localStorage.removeItem("bs_reload_error_counter");
      }
    });
  }

  private getFEVersion(): Promise<VersionFE> {
    return this.http.get<VersionFE>(this.VERSION_URL).toPromise();
  }
}
