import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap, publishReplay, refCount, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Auth } from '../models/auth.model';
import { CleanUpService } from 'src/app/shared/base/services/clean-up.service';
import { CleanUpType } from 'src/app/shared/base/interfaces/clean-up.interface';


// For how many additional miliseconds the auth cookie is stored after it expired.
// Durring this time the token will be automatically refreshed if it is used.
const TIME_TO_REFRESH = 1000 * 60 * 60


@Injectable({
  providedIn: 'root'
})
export class AuthApi {

  private _login: Observable<Auth> = null;

  private STORE_KEY_AUTH = 'AUTH_INFO_STORE';
  private COOKIE_PATH = "/";

  private AUTH_URL = `${environment.API_AUTH_URL}`;
  private API_LOGIN_URL = `${this.AUTH_URL}/connect/token`;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private cleanUpService: CleanUpService,
  ) { }

  public tryLogin(): Observable<Auth> {
    return this.login(null, null, false);
  }

  public login(username: string, password: string, repeatLogin: boolean): Observable<Auth> {
    let data = 'grant_type=password&username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password) + '&scope=' +  encodeURIComponent(environment.API_SCOPE);
    if (!repeatLogin) {
      this._login = null;
      this._login = this.getAuthInfo();
    }

    if (repeatLogin || this._login == null) {
      this._login = null;
      this._login = this.http.post<Auth>(this.API_LOGIN_URL, data, { headers: this.loginHeaders }).pipe(tap((auth: Auth) => {
        this.storeAuthInfo(auth);
      }), publishReplay(1), refCount());
    }

    return this._login;
  }

  public loginWithToken(token: string): Observable<Auth> {
    let data = 'grant_type=token&login_token=' + encodeURIComponent(token) + '&scope=' + encodeURIComponent(environment.API_SCOPE);

    this._login = this.http.post<Auth>(this.API_LOGIN_URL, data, { headers: this.loginHeaders }).pipe(tap((auth: Auth) => {
      this.storeAuthInfo(auth);
    }), publishReplay(1), refCount());

    return this._login;
  }

  public logout() {
    this._login = null;
    this.removeAuthInfo();
    this.cleanUpService.triggerCleanUp(CleanUpType.ALL);
  }

  public getAccessToken(): Observable<string> {
    return this.getAuthInfo().pipe<string>(map((auth: Auth) => {
      return auth.access_token;
    }));
  }

  public refreshAuth(): Observable<Auth> {
    let auth = this._getAuthInfo();
    if (!auth) { return throwError('No auth info stored'); }

    let data = "grant_type=refresh_token&refresh_token=" + encodeURIComponent(auth.refresh_token);
    
    return this.http.post<Auth>(this.API_LOGIN_URL, data, { headers: this.loginHeaders }).pipe(tap((auth: Auth) => {
      this.storeAuthInfo(auth);
    }), publishReplay(1), refCount());
  }

  public get isTokenExpired(): boolean {
    // returns false only if an authInfo is stored and the token is not expired
    let authInfo: Auth = this._getAuthInfo();
    return !authInfo || !authInfo.expires || authInfo.expires < new Date().getTime();
  }

  public _getAccessToken(): string {
    return this._getAuthInfo()?.access_token;
  }


  public getScope(): 'client' | 'coach' {
    return this._getAuthInfo()?.scope.indexOf('mobile') >= 0 ? 'client' : 'coach';
  }

  private _getAuthInfo(): Auth {
    let authString = this.cookieService.get(this.STORE_KEY_AUTH);
    if (!!authString) {
      return JSON.parse(authString);
    }
    return null;
  }

  private getAuthInfo(): Observable<Auth> {
    let authString = this.cookieService.get(this.STORE_KEY_AUTH);
    if (!!authString) {
      return of(JSON.parse(authString)).pipe(publishReplay(1), refCount());
    }
    return throwError('No Auth info stored');
  }

  private storeAuthInfo(auth: Auth): void {
    auth.expires = auth.expires_in * 1000 + new Date().getTime();
    this.cookieService.set(this.STORE_KEY_AUTH, JSON.stringify(auth), new Date(auth.expires + TIME_TO_REFRESH), this.COOKIE_PATH);
  }

  private removeAuthInfo(): void {
    this.cookieService.deleteAll(this.COOKIE_PATH);
  }

  public get loginHeaders(): HttpHeaders {
    return new HttpHeaders({ 'Authorization': 'Basic ' + environment.API_CLIENT_BASE64, 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': '*/*' });
  }

  public get jsonStandardHeaders(): HttpHeaders {
    return new HttpHeaders({ 'Authorization': 'Basic ' + environment.API_CLIENT_BASE64, 'Content-Type': 'application/json', 'Accept': '*/*' });
  }

  public get authHeaders(): HttpHeaders {
    let authString = this.cookieService.get(this.STORE_KEY_AUTH);
    if (!!authString) {
      return new HttpHeaders({
        'Authorization': 'Bearer ' + JSON.parse(authString),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      });
    }
    throw new Error('Not logged in!');
  }
}
