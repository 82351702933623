
export const AppConfig = {
    // current version
    appName: "Bodygee Frontend Framework",
    buildVersion: "V3.00.00",

    colorBefore: "#ff00a8",
    colorBeforeHex: 0xff00a8,
    colorAfter: "#32aae1",
    colorAfterHex: 0x32aae1,
    colorNeutral: '#eee4da',
    colorNeutralHex: 0xeee4da,

    bodyLibraryPageSize: 16,
    bodyLibraryClientPageSize: 200,

    // min 8 chars, max 40 chars, min 1 uppercase, 1 lowercase letter and 1 number
    passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\x21-\x7e\xa1-\xac\xae-\xff]{8,40}$/,

    /*
     * Scale factors used to position the lines of the shape report component
     * when the preview image is used.
    */
    previewImageTop: 0.024,             // space between top of the image and the body in realtion to the image height
    previewImageBottom: 0.024,          // space between bottom of the image and the body in realtion to the image height
    previewImageBodyScale: 0.952,       // height of the body in realtion to the image height

    notificationsPullIntervall: 15*1000 // update notifications every 15sec
}