import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { ServerService } from './shared/base/services/server.service';
import { LocaleService } from './user/api/locale.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private versionChecker: Observable<number>;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private serverService: ServerService,
    private localeService: LocaleService
  ) {
    this.localeService.init();
  }

  ngOnInit(): void {
    this.serverService.checkVersion();

    this.versionChecker = interval(12*60*1000);
    this.subscriptions.add(this.versionChecker.subscribe(() => {
      this.serverService.checkVersion();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
