import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CleanUpType } from '../interfaces/clean-up.interface';

@Injectable({
  providedIn: 'root'
})
export class CleanUpService {

  cleanUp: Subject<CleanUpType> = new Subject<CleanUpType>();
  cleanUpUser: Observable<CleanUpType> = this.cleanUp.pipe(filter(c => c == CleanUpType.USER || c == CleanUpType.ALL));
  cleanUpCoach: Observable<CleanUpType> = this.cleanUp.pipe(filter(c => c == CleanUpType.COACH || c == CleanUpType.ALL));
  cleanUpClient: Observable<CleanUpType> = this.cleanUp.pipe(filter(c => c == CleanUpType.CLIENT || c == CleanUpType.ALL));

  public triggerCleanUp(type: CleanUpType): void {
    this.cleanUp.next(type);
    console.debug("-- Clean up", type)
  }
}