import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { IonicModule } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserInterceptor } from './user/interceptors/user.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { registerLocaleData } from '@angular/common';

import localeEn from "@angular/common/locales/en"; // en-US
import localeEnGB from "@angular/common/locales/en-GB";
import localeDe from "@angular/common/locales/de";
import localeDeCH from "@angular/common/locales/de-CH";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import localeIt from "@angular/common/locales/it";
import localeJa from "@angular/common/locales/ja";
import localePt from "@angular/common/locales/pt"; // pt-BR
import localePtPt from "@angular/common/locales/pt-PT";


registerLocaleData(localeEn);
registerLocaleData(localeEnGB);
registerLocaleData(localeDe);
registerLocaleData(localeDeCH);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeJa);
registerLocaleData(localePt);
registerLocaleData(localePtPt);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({mode: 'ios', swipeBackEnabled: false, hardwareBackButton: true}), 
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
